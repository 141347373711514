import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["rows", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_Field = _resolveComponent("Field");
    var _component_ErrorMessage = _resolveComponent("ErrorMessage");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_Field, {
            name: $props.name,
            modelValue: $setup.modell,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (($setup.modell) = $event); }),
            onInput: _cache[1] || (_cache[1] = function ($event) { return (_ctx.$emit('update:modelValue', $event.target.value)); })
        }, {
            default: _withCtx(function (_a) {
                var field = _a.field, errors = _a.errors;
                return [
                    _createElementVNode("textarea", _mergeProps({
                        rows: $props.rows,
                        class: "form-control form-control-lg form-control-solid",
                        placeholder: $props.placeholder
                    }, field, {
                        class: { error_border: errors.length > 0 },
                        style: { height: $setup.heightStyle }
                    }), null, 16, _hoisted_1)
                ];
            }),
            _: 1
        }, 8, ["name", "modelValue"]),
        _createVNode(_component_ErrorMessage, {
            style: { "color": "#f41127" },
            name: $props.name
        }, null, 8, ["name"])
    ], 64));
}
