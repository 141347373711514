import { ref, watch, computed } from "vue";
import { Field, ErrorMessage } from "vee-validate";
export default {
    name: "BaseTextArea",
    inheritAttrs: false,
    components: {
        Field: Field,
        ErrorMessage: ErrorMessage,
    },
    props: {
        name: String,
        modelValue: {
            type: String,
        },
        rows: {
            type: Number,
            default: 4,
        },
        placeholder: {
            type: String,
            default: "",
        },
        default_height: {
            type: Number,
            default: 120,
        },
    },
    setup: function (props) {
        var modell = ref("");
        watch(function () { return props.modelValue; }, function (val) {
            modell.value = val !== null && val !== void 0 ? val : "";
        });
        var heightStyle = computed(function () {
            return props.default_height + "px";
        });
        return {
            modell: modell,
            heightStyle: heightStyle,
        };
    },
};
